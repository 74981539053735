define(["./mapsModule", "../arrays/arrays"], function (mapsModule, arrays) {
    "use strict";

    var AERO_PROVIDER_TYPE = "br.com.neolog.model.parameters.routing.PathProvider$PathProviderType.AERO";
    /**
     * @ngdoc service
     * @name mapsModule.mapsRendererService
     * @description
     * Serviço que recebe o resultado do {@link mapsModule.mapsDirectionsService mapsDirectionsService} e
     * renderiza os resultados no mapa, na forma de uma polyline.
     * */
    return mapsModule.service("mapsRendererService", ["uiGmapGoogleMapApi", "uiGmapIsReady", "$rootScope", function (uiGmapGoogleMapApi, uiGmapIsReady, $rootScope) {
        var highlightedTrips = [];
        var polylinesByRoute = {};

        function getPolylines(route) {
            return polylinesByRoute[route.presentationNode.type.name + route.presentationNode.id];
        }

        function resetOrCreatePolylines(route) {
            polylinesByRoute[route.presentationNode.type.name + route.presentationNode.id] = [];
        }

        var OPACITY_HIGH = 1;
        var OPACITY_LOW = 0.3;

        return {
            renderResult: function (directionResult) {
                var maps = null;
                uiGmapGoogleMapApi.then(function (_maps) {
                    maps = _maps;
                    return uiGmapIsReady.promise(1);
                }).then(function (instances) {
                    var map = instances[0].map;
                    directionResult.forEach(function (route) {
                        createPolylines(route, map);

                        $rootScope.$watch(function () {
                            return route.visible;
                        }, function (visible) {
                            if (visible) {
                                if (!getPolylines(route).length) {
                                    createPolylines(route, map);
                                }
                                return;
                            }
                            removePolylines(route);
                        });

                        $rootScope.$watch(function () {
                            return route.color;
                        }, function (color) {
                            getPolylines(route).forEach(function (polyline) {
                                polyline.setOptions({strokeColor: color});
                                polyline.setMap(map);
                            });
                        });
                        $rootScope.$watch(function () {
                            return route.zIndex;
                        }, function (zIndex) {
                            getPolylines(route).forEach(function (polyline) {
                                polyline.setOptions({zIndex: zIndex});
                                polyline.setMap(map);
                            });
                            $rootScope.$emit("onChangeStopsZIndex", route);
                        });
                        if (route.startingLeg) {
                            // Círculos cheios: estão cheios de combustível
                            renderLeg(route.startingLeg, route.color, 0.8);
                        }
                        if (route.endingLeg) {
                            // Círculos vazios: deve estar no fim do tanque de combustível
                            renderLeg(route.endingLeg, route.color, 0);
                        }

                        function renderLeg(leg, color, fillOpacity) {
                            var iconSequence = {
                                icon: {
                                    fillColor: color,
                                    fillOpacity: fillOpacity,
                                    scale: 3,
                                    strokeColor: "#000000",
                                    strokeWeight: 2,
                                    path: maps.SymbolPath.CIRCLE
                                },
                                repeat: "20px"
                            };
                            var directionsLegRenderer = new maps.DirectionsRenderer({
                                suppressMarkers: true,
                                preserveViewport: true,
                                map: map,
                                polylineOptions: {
                                    strokeColor: "#000",
                                    strokeWeight: 0,
                                    icons: [iconSequence]
                                }
                            });
                            directionsLegRenderer.setDirections(leg.directions);
                            $rootScope.$watch(function () {
                                return route.visible;
                            }, function (visible) {
                                if (visible) {
                                    directionsLegRenderer.setMap(map);
                                } else {
                                    directionsLegRenderer.setMap(null);
                                }
                            });
                            $rootScope.$watch(function () {
                                return route.color;
                            }, function (color) {
                                iconSequence.icon.fillColor = color;
                                iconSequence.icon.strokeColor = color;
                                directionsLegRenderer.setOptions({
                                    polylineOptions: {
                                        icons: [iconSequence],
                                        strokeWeight: 0
                                    }
                                });
                                directionsLegRenderer.setMap(directionsLegRenderer.getMap());
                            });
                        }
                    });

                    function createPolylines(route, map) {
                        var polylines = getPolylines(route);
                        if (polylines && polylines.length) {
                            removePolylines(route);
                        }
                        resetOrCreatePolylines(route);
                        route.results.forEach(function (result) {
                            result.routes.forEach(function (gMapsRoute) {
                                var polyline;
                                if (route.pathProviderType === AERO_PROVIDER_TYPE) {
                                    var aeroPath = [];
                                    gMapsRoute.legs.forEach(function (leg) {
                                        aeroPath.push(leg.start_location);
                                        aeroPath.push(leg.end_location);
                                        leg.steps = [];
                                    });
                                    polyline = new maps.Polyline({
                                            path: aeroPath,
                                            strokeColor: route.color,
                                            geodesic: true,
                                            strokeWeight: 5
                                        }
                                    );
                                } else {
                                    polyline = new maps.Polyline({
                                            path: createPathFromRoute(gMapsRoute),
                                            strokeColor: route.color,
                                            icons: gMapsRoute.routeIcons,
                                            strokeOpacity: gMapsRoute.routeIcons ? 0 : 1,
                                            strokeWeight: 5
                                        }
                                    );
                                }

                                getPolylines(route).push(polyline);
                            });
                        });
                        drawRoutePolylines(route, map);
                    }

                    function createPathFromRoute(gMapsRoute) {
                        if (gMapsRoute.legs.length === 0) {
                            return gMapsRoute.overview_path;
                        }
                        return gMapsRoute.legs.map(function (leg) {
                            return leg.steps.map(function convertStep(step) {
                                var detailedSteps = step.path;
                                if (step.steps) {
                                    detailedSteps = step.steps.map(convertStep);
                                }
                                return [step.start_location].concat(detailedSteps).concat(step.end_location);
                            }).reduce(flatten);
                        }).reduce(flatten);

                        function flatten(a, b) {
                            return a.concat(b);
                        }
                    }

                    function drawRoutePolylines(route, map) {
                        getPolylines(route).forEach(function (polyline) {
                            polyline.setMap(map);
                            maps.event.addListener(polyline, "click", function () {
                                $rootScope.$broadcast("OnRouteClick", route.presentationNode);
                            });
                        });
                    }

                    function removePolylines(route) {
                        getPolylines(route).forEach(function (polyline) {
                            polyline.setMap(null);
                            maps.event.clearInstanceListeners(polyline);
                        });
                        getPolylines(route).length = 0;
                    }
                });
            },
            resetHightlights: function () {
                highlightedTrips.length = 0;
            },
            highlightTripRoute: function (routes, tripId) {
                if (arrays.contains(highlightedTrips, tripId)) {
                    arrays.remove(highlightedTrips, tripId);
                } else {
                    highlightedTrips.push(tripId);
                }

                routes.forEach(function (route) {
                    if (arrays.contains(highlightedTrips, route.presentationNode.id) || !highlightedTrips.length) {
                        changeRouteOpacity(route, OPACITY_HIGH);
                        return;
                    }
                    changeRouteOpacity(route, OPACITY_LOW);
                });
                return arrays.copy(highlightedTrips);

                function changeRouteOpacity(route, opacity) {
                    var polylineOptions = {
                        strokeOpacity: opacity
                    };
                    if (highlightedTrips.length) {
                        var routeZIndex = arrays.indexOf(highlightedTrips, route.presentationNode.id);
                        polylineOptions.zIndex = routeZIndex;
                    }
                    getPolylines(route).forEach(function (polyline) {
                        polyline.setOptions(polylineOptions);
                    });
                }
            }
        };
    }]);
});