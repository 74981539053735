define(["./simulationModule", "lodash"], function (module, _) {
    "use strict";

    return module.provider("simulationUrls", [function () {
        var urls = {
            baseUrl: null, // serverConfig.baseUrl + serverConfig.services.programming.simulation.baseUrl
            statusUrl: null, // baseUrl + serverConfig.services.programming.simulation.status
            resultUrl: null, // baseUrl + serverConfig.services.programming.simulation.result
            simulationActions: null, // baseUrl + "actions"
            importData: null
        };

        this.$get = [function () {
            checkUrls(urls);

            return {
                baseUrl: function baseUrl() {
                    return urls.baseUrl;
                },
                statusUrl: function statusUrl() {
                    return urls.statusUrl;
                },
                resultUrl: function resultUrl() {
                    return urls.resultUrl;
                },
                simulationActions: function simulationActions() {
                    return urls.simulationActions;
                },
                importData: function importData(){
                    return urls.importData;
                }
            };
        }];

        this.configure = function (newUrls) {
            checkUrls(newUrls);
            urls = newUrls;
        };

        function checkUrls(newUrls) {
            var errors = Object.keys(urls).map(function (key) {
                var url = newUrls[key];
                if (!_.isString(url) || url.trim().length === 0) {
                    return key;
                }
                return null;
            }).filter(_.negate(_.isNil));
            if (errors.length > 0) {
                throw new Error("simulationUrls: some urls are missing: " + errors.join(","));
            }
        }
    }]);
});