define(["./mapsModule", "../arrays/arrays", "angular"], function (mapsModule, arrays, angular) {
    "use strict";

    mapsModule.directive("nlgPolygonDrawingManager", ["uiGmapGoogleMapApi", "uiGmapIsReady", "messagesModal", "FreeHandControlPanel", "FreeHandLifeCycle",
        function (uiGmapGoogleMapApi, uiGmapIsReady, messagesModal, FreeHandControlPanel, FreeHandLifeCycle) {
            return {
                restrict: "E",
                scope: {
                    drawing: "=",
                    polygonElements: "<?",
                    onRegisterApi: "&?",
                    onDrawPolygon: "&?",
                    onDeletePolygon: "&?",
                    onUpdatePolygon: "&?",
                    readOnly: "<?"
                },
                link: function postLink($scope, element) {
                    if (!$scope.onRegisterApi) {
                        throw new Error("'onRegisterApi' not informed.");
                    }
                    $scope.onDrawPolygon = $scope.onDrawPolygon || angular.noop;
                    $scope.onDeletePolygon = $scope.onDeletePolygon || angular.noop;
                    $scope.onUpdatePolygon = $scope.onUpdatePolygon || angular.noop;

                    var editable = $scope.readOnly ? !$scope.readOnly : true;

                    var googleMapContainer = angular.element("ui-gmap-drawing-manager", element);
                    if (!googleMapContainer.length) {
                        throw new Error("nlgPolygonDrawingManager requires a 'ui-gmap-drawing-manager' in the child hierarchy.");
                    }

                    $scope.allPolygons = [];
                    $scope.singleMap = null;
                    $scope.polygonElements = $scope.polygonElements || [];
                    $scope.drawing = $scope.drawing || {};
                    $scope.drawing.control = $scope.drawing.control || {};

                    uiGmapGoogleMapApi.then(function (maps) {
                        $scope.maps = maps;

                        $scope.drawing.options = {
                            drawingMode: !editable ? null : maps.drawing.OverlayType.POLYGON,
                            drawingControl: editable,
                            drawingControlOptions: {
                                position: maps.ControlPosition.TOP_CENTER,
                                drawingModes: !editable ? [] : [
                                    maps.drawing.OverlayType.POLYGON
                                ]
                            },
                            polygonOptions: {
                                fillColor: "#18bc9c",
                                fillOpacity: 0.35,
                                strokeWeight: 1,
                                zIndex: 1,
                                clickable: true,
                                editable: editable
                            }
                        };

                        $scope.centerMapOnCoords = function (map, coords) {
                            var bounds = new $scope.maps.LatLngBounds();
                            coords.forEach(function (coord) {
                                bounds.extend(coord);
                            });
                            if (!bounds.isEmpty()) {
                                map.fitBounds(bounds);
                            }
                            var zoom = map.getZoom();
                            map.setZoom(Math.min(zoom, 15));
                        };

                        $scope.onCreatePolygon = function (polygon) {
                            if (polygon.getPath().getLength() <= 2) {
                                polygon.setMap(null);
                                return;
                            }
                            $scope.allPolygons.push(polygon);
                            registerDeleteListener(polygon);
                            $scope.onDrawPolygon({
                                polygon: convertPolygonToModel(polygon)
                            });
                        };

                        $scope.$watch("drawing.control.getDrawingManager", function (getDrawingManager) {
                            if (!getDrawingManager || !getDrawingManager()) {
                                return;
                            }
                            $scope.drawingManager = getDrawingManager();
                            maps.event.addListener($scope.drawingManager, "polygoncomplete", onCreatePolygon);

                            function onCreatePolygon(polygon) {
                                if (polygon.getPath().getLength() <= 2) {
                                    polygon.setMap(null);
                                    return;
                                }
                                if ($scope.onUpdatePolygon !== angular.noop && editable) {
                                    registerUpdateListener(polygon);
                                }
                                $scope.allPolygons.push(polygon);
                                registerDeleteListener(polygon);
                                $scope.onDrawPolygon({
                                    polygon: convertPolygonToModel(polygon)
                                });
                            }
                        });

                        return uiGmapIsReady.promise(1);
                    }).then(function (mapInstances) {

                        $scope.object = {
                            isDrawingMode: false
                        };
                        $scope.singleMap = mapInstances[0].map;

                        if (editable) {
                            var centerControl = new FreeHandControlPanel(function actionListener() {
                                $scope.object.isDrawingMode = !$scope.object.isDrawingMode;
                                $scope.drawing.options.drawingControl = !$scope.object.isDrawingMode;
                                $scope.drawing.options.drawingMode = $scope.drawing.options.drawingControl ? $scope.maps.drawing.OverlayType.POLYGON : null;
                                $scope.$apply();
                            });

                            var lifeCycle = new FreeHandLifeCycle($scope, centerControl);
                            lifeCycle.activate();
                        }

                        drawPolygonsAndCenter($scope.polygonElements, true);

                        var polygonDrawerApi = {};

                        polygonDrawerApi.getPolygons = function () {
                            return $scope.allPolygons.map(function (eachPolygon) {
                                return convertPolygonToModel(eachPolygon);
                            });
                        };

                        polygonDrawerApi.addPolygons = function (polygons, readOnly) {
                            drawPolygonsAndCenter(polygons, readOnly);
                        };

                        polygonDrawerApi.setPolygons = function (polygons, readOnly) {
                            clearPolygons();
                            drawPolygonsAndCenter(polygons, readOnly);
                        };

                        polygonDrawerApi.centerMapOnPoints = function (points) {
                            $scope.centerMapOnCoords($scope.singleMap, convertModelToCoordinates(points));
                        };


                        $scope.onRegisterApi({api: polygonDrawerApi});

                        function clearPolygons() {
                            $scope.allPolygons.forEach(function (polygon) {
                                polygon.setMap(null);
                            });
                            arrays.clear($scope.allPolygons);
                        }

                        function drawPolygonsAndCenter(polygons, readOnly) {
                            readOnly = angular.isDefined(readOnly) ? readOnly : editable;
                            polygons.forEach(function drawEach(eachPolygon) {
                                var polygonCoordinates = convertModelToCoordinates(eachPolygon.points);
                                var options = angular.copy($scope.drawing.options.polygonOptions);
                                options.editable = !readOnly;
                                if (angular.isDefined(eachPolygon.editable)) {
                                    options.editable = eachPolygon.editable;
                                }
                                var polygon = new $scope.maps.Polygon(options);

                                polygon.setPaths(polygonCoordinates);
                                if ($scope.onUpdatePolygon !== angular.noop && options.editable) {
                                    registerUpdateListener(polygon);
                                }
                                polygon.setMap($scope.singleMap);
                                registerDeleteListener(polygon);
                                $scope.allPolygons.push(polygon);
                            });
                            var allCoordinates = [];
                            $scope.allPolygons.forEach(function (polygon) {
                                polygon.getPath().getArray().forEach(function (latLng) {
                                    allCoordinates.push(latLng);
                                });
                            });
                            $scope.centerMapOnCoords($scope.singleMap, allCoordinates);
                        }


                        function convertModelToCoordinates(points) {
                            return points.map(function (eachPoint) {
                                return new $scope.maps.LatLng(parseFloat(eachPoint.lat), parseFloat(eachPoint.lng));
                            });
                        }
                    });

                    function registerUpdateListener(polygon) {
                        var path = polygon.getPath();
                        $scope.maps.event.addListener(path, "set_at", function () {
                            var polygonsConverted = [];
                            $scope.allPolygons.forEach(function (polygon) {
                                polygonsConverted.push(convertPolygonToModel(polygon));
                            });
                            $scope.onUpdatePolygon({polygons: polygonsConverted});
                        });

                        $scope.maps.event.addListener(path, "insert_at", function () {
                            var polygonsConverted = [];
                            $scope.allPolygons.forEach(function (polygon) {
                                polygonsConverted.push(convertPolygonToModel(polygon));
                            });
                            $scope.onUpdatePolygon({polygons: polygonsConverted});
                        });
                    }

                    function convertPolygonToModel(polygon) {
                        var points = [];
                        polygon.getPaths().getAt(0).forEach(function (eachPoint) {
                            points.push({
                                lat: eachPoint.lat(),
                                lng: eachPoint.lng()
                            });
                        });

                        return {
                            "editable": polygon.editable,
                            "points": points
                        };
                    }

                    function registerDeleteListener(polygon) {
                        $scope.maps.event.addListener(polygon, "rightclick", function (event) {
                            if (angular.isNumber(event.vertex) && polygon.getPath().getLength() > 3) {
                                polygon.getPath().removeAt(event.vertex);
                            } else {
                                messagesModal.cancellable("dialog.warning", ["maps.polygon.delete.confirm"])
                                    .then(function () {
                                        arrays.remove($scope.allPolygons, polygon);
                                        polygon.setMap(null);
                                        $scope.onDeletePolygon({
                                            polygon: convertPolygonToModel(polygon)
                                        });
                                    });
                            }
                        });
                    }
                }
            };
        }
    ]);

    mapsModule.service("FreeHandLifeCycle", ["pointReductionService", "FreeHandDrawerContextMenu", function (pointReductionService, FreeHandDrawerContextMenu) {
        function FreeHandLifeCycle(scope, centerControl) {
            this.scope = scope;
            this.centerControl = centerControl;
        }

        FreeHandLifeCycle.prototype.activate = function () {
            this.scope.singleMap.controls[this.scope.maps.ControlPosition.TOP_CENTER].push(this.centerControl.getElement());

            var isContextMenuOpen = false;
            var optionsMap = {};
            var currentFreeHandPolygon = null;
            var contextMenu = null;
            var $scope = this.scope;
            $scope.$watch("object.isDrawingMode", function (isDrawingMode) {
                if (!isDrawingMode) {
                    optionsMap.draggable = true;
                    optionsMap.disableDoubleClickZoom = false;
                    isContextMenuOpen = false;
                    $scope.singleMap.setOptions(optionsMap);
                    $scope.maps.event.clearListeners($scope.singleMap, "mousemove");
                    $scope.maps.event.clearListeners($scope.singleMap, "mousedown");
                    $scope.maps.event.clearListeners($scope.singleMap, "mouseup");
                    if (currentFreeHandPolygon) {
                        currentFreeHandPolygon.setMap(null);
                        currentFreeHandPolygon = null;
                    }
                    if (contextMenu) {
                        contextMenu.hide();
                        contextMenu = null;
                    }
                    return;
                }

                var isFreeHandDrawing = true;
                optionsMap.draggable = false;
                optionsMap.disableDoubleClickZoom = true;

                $scope.singleMap.setOptions(optionsMap);

                currentFreeHandPolygon = new $scope.maps.Polyline({clickable: false});

                $scope.maps.event.addListener($scope.singleMap, "mousedown", function () {
                    if (!isFreeHandDrawing) {
                        return;
                    }
                    $scope.maps.event.addListener($scope.singleMap, "mousemove", function (event) {
                        $scope.$apply(function () {
                            var latLng = new $scope.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                            currentFreeHandPolygon.getPath().push(latLng);
                            currentFreeHandPolygon.setMap($scope.singleMap);
                        });
                    });
                });


                $scope.maps.event.addListener($scope.singleMap, "mouseup", function (mouseEvent) {
                    $scope.maps.event.clearListeners($scope.singleMap, "mousemove");
                    isFreeHandDrawing = false;
                    if (currentFreeHandPolygon.getPath().getLength() < 2) {
                        isFreeHandDrawing = true;
                        currentFreeHandPolygon.setMap(null);
                        currentFreeHandPolygon = new $scope.maps.Polyline({clickable: false});
                        return;
                    }
                    if (isContextMenuOpen) {
                        return;
                    }
                    if (contextMenu === null) {
                        contextMenu = FreeHandDrawerContextMenu.create($scope.singleMap, mouseEvent.latLng);
                    }
                    isContextMenuOpen = true;
                    $scope.maps.event.addListener(contextMenu, "menuItemSelected", function (latLng, eventName) {
                        switch (eventName) {
                            case "saveClick":
                                var simplifiedPoints = pointReductionService(currentFreeHandPolygon.getPath().getArray(), $scope.singleMap.getZoom());
                                var options = angular.copy($scope.drawing.options.polygonOptions);
                                var polygon = new $scope.maps.Polygon(options);
                                polygon.setPaths(simplifiedPoints);
                                $scope.onCreatePolygon(polygon);
                                var allCoordinates = [];
                                $scope.allPolygons.forEach(function (eachPolygon) {
                                    eachPolygon.getPath().getArray().forEach(function (latLng) {
                                        allCoordinates.push(latLng);
                                    });
                                });
                                $scope.centerMapOnCoords($scope.singleMap, allCoordinates);
                                polygon.setMap($scope.singleMap);
                                currentFreeHandPolygon.setMap(null);
                                currentFreeHandPolygon = new $scope.maps.Polyline({clickable: false});
                                contextMenu = null;
                                isFreeHandDrawing = true;
                                isContextMenuOpen = false;
                                break;
                            case "cancelClick":
                                currentFreeHandPolygon.setMap(null);
                                currentFreeHandPolygon = new $scope.maps.Polyline({clickable: false});
                                contextMenu = null;
                                isFreeHandDrawing = true;
                                isContextMenuOpen = false;
                                break;
                        }
                    });
                });
            });
        };

        return FreeHandLifeCycle;
    }]);
});
